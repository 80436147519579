import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";
import {
  Reports,
  resetFilters,
  setFilters,
} from "../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const TicketCallRecordsFilters = () => {
  const dispatch = useDispatch();
  const {
    filters: { ticketRecordsReport },
    loading,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    return () => dispatch(resetFilters({ type: "ticketRecordsReport" }));
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" component="div">
            Quality Report
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              label={"Extension"}
              size="small"
              fullWidth
              value={ticketRecordsReport.extension}
              onChange={(e) => {
                dispatch(
                  setFilters({
                    type: "ticketRecordsReport",
                    data: {
                      extension: e.target.value,
                    },
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={ticketRecordsReport.start_date}
                label="Start Date"
                onChange={(newValue) => {
                  dispatch(
                    setFilters({
                      type: "ticketRecordsReport",
                      data: {
                        start_date: newValue
                          ? dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                          : null,
                      },
                    })
                  );
                }}
                renderInput={(props) => <TextField {...props} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={ticketRecordsReport.end_date}
                label="End Date"
                onChange={(newValue) => {
                  dispatch(
                    setFilters({
                      type: "ticketRecordsReport",
                      data: {
                        end_date: newValue
                          ? dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                          : null,
                      },
                    })
                  );
                }}
                renderInput={(props) => <TextField {...props} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={() => {
                dispatch(Reports.fetch("ticketRecordsReport"));
              }}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Show results
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TicketCallRecordsFilters;
