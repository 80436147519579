import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import CanIsee from "../../../components/utils/CanIsee";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import { VolumeDown, VolumeUp, VolumeOff } from "@mui/icons-material";

const PlayCallRecord = ({ handleClose, rec_path, open, ...props }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [hasEnded, setHasEnded] = useState(false);

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value % 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (hasEnded) {
      audio.currentTime = 0;
      audio.play();
      setIsPlaying(true);
      setHasEnded(false);
    } else if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    } else {
      audio.play();
      setIsPlaying(true);
    }
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    setProgress(audio.currentTime);
  };

  const handleLoadedMetadata = () => {
    const audio = audioRef.current;
    setDuration(audio.duration);
  };

  const handleSliderChange = (event, newValue) => {
    const audio = audioRef.current;
    audio.currentTime = newValue;
    setProgress(newValue);
    if (hasEnded) {
      setHasEnded(false);
    }
  };

  const handleVolumeChange = (event, newValue) => {
    const audio = audioRef.current;
    setVolume(newValue);
    audio.volume = newValue;
  };

  const handleMuteAudio = () => {
    const audio = audioRef.current;
    if (volume !== 0) {
      setVolume(0);
      audio.volume = 0;
    } else {
      setVolume(0.8);
      audio.volume = 0.8;
    }
  };

  const handleAudioEnd = () => {
    setIsPlaying(false);
    setHasEnded(true);
  };

  const { data: audioFile, isFetching } = useFetch(
    `/pbx-service/recording?rec_path=${rec_path}`,
    {
      responseType: "blob",
      formatData: (res) => res.data,
      onSuccess: (data) => {
        let url = window.URL.createObjectURL(data);
        setAudioUrl(url);
      },
    }
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography>Play record</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "30vh",
        }}
      >
        {!isFetching ? (
          <Card
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <audio
                src={audioUrl}
                id="audio_player"
                ref={audioRef}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={handleAudioEnd}
              ></audio>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ alignItems: "center", mb: 1 }}
                >
                  <Typography sx={{ width: 50, textAlign: "left" }}>
                    {formatDuration(Math.floor(progress))}
                  </Typography>
                  <Slider
                    value={progress}
                    min={0}
                    max={duration}
                    size="small"
                    onChange={(e, newValue) => handleSliderChange(e, newValue)}
                    aria-labelledby="progress-slider"
                    sx={{
                      height: 4,
                      transition: "all 0.3s ease",
                      "& .MuiSlider-thumb": {
                        width: 8,
                        height: 8,
                        transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                        "&::before": {
                          boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                        },
                        "&.Mui-active": {
                          width: 20,
                          height: 20,
                        },
                      },
                      "& .MuiSlider-rail": {
                        opacity: 0.28,
                      },
                    }}
                  />
                  <Typography sx={{ width: 50, textAlign: "right" }}>
                    {formatDuration(
                      Math.floor(duration) - Math.floor(progress)
                    )}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pb: 1,
                    justifyContent: "center",
                  }}
                >
                  <IconButton aria-label="play/pause" onClick={handlePlayPause}>
                    {hasEnded ? (
                      <ReplayCircleFilledIcon
                        sx={{ height: 30, width: 30 }}
                        color="primary"
                      />
                    ) : isPlaying ? (
                      <PauseCircleFilledIcon
                        sx={{ height: 30, width: 30 }}
                        color="primary"
                      />
                    ) : (
                      <PlayCircleIcon
                        sx={{ height: 30, width: 30 }}
                        color="primary"
                      />
                    )}
                  </IconButton>
                  <CanIsee UserPermissions={"pbx_calls_download"}>
                    <IconButton
                      aria-label="download"
                      onClick={() => {
                        let a = document.createElement("a");
                        a.href = audioUrl;
                        a.download = "file.wav";
                        a.click();
                      }}
                    >
                      <DownloadForOfflineIcon
                        sx={{ height: 30, width: 30 }}
                        color="primary"
                      />
                    </IconButton>
                  </CanIsee>
                </Box>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <IconButton onClick={handleMuteAudio}>
                    {volume !== 0 ? (
                      <VolumeUp
                        sx={{ height: 30, width: 30 }}
                        color="primary"
                      />
                    ) : (
                      <VolumeOff
                        sx={{ height: 30, width: 30 }}
                        color="primary"
                      />
                    )}
                  </IconButton>
                  <Slider
                    value={volume}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e, newValue) => handleVolumeChange(e, newValue)}
                    aria-labelledby="volume-slider"
                    size="small"
                    sx={{ width: "50%" }}
                  />
                  {/* <VolumeUp sx={{ height: 30, width: 30 }} color="primary" /> */}
                </Stack>
              </CardContent>
            </Box>
          </Card>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PlayCallRecord;
