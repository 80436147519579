import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderReportFilter } from "./filters";
import { clearReports, setDialog } from "../../app/slices/reportsSlice";
import { ReportsDataGrid } from "./DataGrid/ReportsDataGrid";
import OrdersInvoice from "../../components/orders/OrdersInvoice";
import PlayCallRecord from "../tickets/TicketDetails/PlayCallRecord";
const Reports = () => {
  let reportsNames = [
    {
      id: "departmentTicketsSummary",
      name: "Department tickets summary",
      permission_name: "department_tickets_summary_view",
    },
    {
      id: "ticketsDurationsSummary",
      name: "Tickets Durations Summary",
      permission_name: "tickets_durations_view",
    },
    {
      id: "cabinetItemsReport",
      name: "Cabinet Items",
      permission_name: "cabinet_items_report",
    },
    {
      id: "ticketRecordsReport",
      name: "Quality Report",
      permission_name: "ticket_quality_report",
    },
    {
      id: "missedCallReport",
      name: "Missed Calls Report",
      permission_name: "ticket_quality_report",
    },
  ];
  const { permissions } = useSelector((state) => state.profile.user.info);
  const { order_invoice } = useSelector((state) => state.orders);
  const { reports } = useSelector((state) => state.reports);
  const {
    dialog: { ticketRecordsReport },
  } = useSelector((state) => state.reports);
  const [reportName, setReportName] = useState("");
  const dispatch = useDispatch();
  const reportsByPermissions = () => {
    const result = reportsNames.filter((item) =>
      permissions.includes(item.permission_name)
    );
    return result ? result : [];
  };
  useEffect(() => {
    if (permissions.length > 0) {
      setReportName(reportsByPermissions()[0].id);
    }
  }, [permissions]);

  useEffect(() => {
    dispatch(clearReports());
  }, [reportName]);
  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel
                  id="simple-label"
                  sx={{
                    fontSize: "0.75rem",
                  }}
                >
                  Choose Report
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    value={reportName}
                    size="small"
                    labelId="select-label"
                    onChange={(e) => {
                      setReportName(e.target.value);
                      dispatch(clearReports());
                    }}
                  >
                    {reportsByPermissions().map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {renderReportFilter(reportName).filter}
      </Grid>
      <Grid item xs={12} m={1}>
        <ReportsDataGrid
          reportName={reportName}
          isDetailed={reportName === "departmentTicketsSummary"}
        />
      </Grid>
      {order_invoice.dialog && <OrdersInvoice />}
      {ticketRecordsReport.open && (
        <PlayCallRecord
          open={ticketRecordsReport.open}
          rec_path={ticketRecordsReport.details.recording_url}
          handleClose={() =>
            dispatch(setDialog({ type: "ticketRecordsReport", details: {} }))
          }
        />
      )}
    </Grid>
  );
};

export default Reports;
