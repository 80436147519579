import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import {
  Reports,
  resetFilters,
  setFilters,
} from "../../../app/slices/reportsSlice";
import { SelectCabinet } from "../../../components/Selects/SelectCabinet";
import { LoadingButton } from "@mui/lab";

const CabinetItemsFilters = () => {
  const dispatch = useDispatch();
  const {
    filters: { cabinetItemsReport },
  } = useSelector((state) => state.reports);

  useEffect(() => {
    return () => dispatch(resetFilters({ type: "cabinetItemsReport" }));
  }, []);
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" component="div">
            Cabinet Items
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SelectCabinet
              fullWidth
              onChange={(e, value) => {
                dispatch(
                  setFilters({
                    type: "cabinetItemsReport",
                    data: { cabinet_id: value ? value.id : null },
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(Reports.fetch("cabinetItemsReport"));
              }}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Show results
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CabinetItemsFilters;
