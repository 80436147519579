import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoPlayOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../app/slices/reportsSlice";

export const TicketCallRecordsColumns = [
  {
    fileld: "ticket_number",
    headerName: "Ticket No.",
    flex: 1,
    renderCell: (params) => {
      return (
        <a
          href={`/tickets/${params.row.ticket_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip title={params.row.ticket_number}>
            <Typography>{params.row.ticket_number}</Typography>
          </Tooltip>
        </a>
      );
    },
  },
  {
    field: "caller",
    headerName: "Source and Destination",
    flex: 1,
    renderCell: (params) => {
      return (
        <Stack direction="row" alignItems={"center"}>
          <Chip label={params.row.caller} color="success" />
          <AiOutlineArrowRight
            size={20}
            style={{
              margin: " 0 10px 0 10px",
            }}
          />
          <Chip label={params.row.callee} color="error" />
        </Stack>
      );
    },
  },
  {
    field: "start",
    headerName: "Started at",
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography color="initial">
          {dayjs(params.row.start).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      );
    },
  },
  {
    field: "end",
    headerName: "Ended at",
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography color="initial">
          {dayjs(params.row.end).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      );
    },
  },
  {
    field: "recording_url",
    headerName: "Recording",
    flex: 1,
    renderCell: (params) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dispatch = useDispatch();
      return (
        <Box>
          <Chip
            variant="outlined"
            sx={{
              mx: 1,
            }}
            color="primary"
            onClick={() =>
              dispatch(
                setDialog({
                  type: "ticketRecordsReport",
                  details: { recording_url: params.row.recording_url },
                })
              )
            }
            label={"Play"}
            icon={<IoPlayOutline size={20} />}
          />
        </Box>
      );
    },
  },
];
