import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";
import {
  Reports,
  resetFilters,
  setFilters,
} from "../../../app/slices/reportsSlice";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SelectSrcTrunk from "../../../components/Selects/SelectSrcTrunk";

const MissedCallFilters = () => {
  const dispatch = useDispatch();
  const {
    filters: { missedCallReport },
    loading,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    return () => dispatch(resetFilters({ type: "missedCallReport" }));
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" component="div">
            Missed Calls Report
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={missedCallReport.start_date}
                label="Start Date"
                onChange={(newValue) => {
                  dispatch(
                    setFilters({
                      type: "missedCallReport",
                      data: {
                        start_date: newValue
                          ? dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                          : null,
                      },
                    })
                  );
                }}
                renderInput={(props) => <TextField {...props} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={missedCallReport.end_date}
                label="End Date"
                onChange={(newValue) => {
                  dispatch(
                    setFilters({
                      type: "missedCallReport",
                      data: {
                        end_date: newValue
                          ? dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss")
                          : null,
                      },
                    })
                  );
                }}
                renderInput={(props) => <TextField {...props} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <SelectSrcTrunk
              value={missedCallReport.src_trunk_name}
              onChange={(e, newValue) => {
                dispatch(
                  setFilters({
                    type: "missedCallReport",
                    data: {
                      src_trunk_name: newValue,
                    },
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={() => {
                dispatch(Reports.fetch("missedCallReport"));
              }}
              sx={{
                textTransform: "capitalize",
              }}
            >
              Show results
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MissedCallFilters;
