import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";

import SideBar from "./Sidebar";
import AppBar from "./AppBar";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "../../app/slices/profileSlice";
import Cookies from "js-cookie";
import useFetch from "../../hooks/useFetch";
import { setPermissions, setRoles } from "../../app/slices/permissionsSlice";
import OneSignal from "react-onesignal";

async function runOneSignal(userId) {
  await OneSignal.init({
    appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    // allowLocalhostAsSecureOrigin: true,
    // notifyButton: {
    //   enable: true,
    // },
    // serviceWorkerPath: "/OneSignalSDKWorker.js",
    // serviceWorkerUpdaterPath: "/OneSignalSDKUpdaterWorker.js",
  });
  await OneSignal.Slidedown.promptPush();
  await OneSignal.login(userId);
  await OneSignal.User.PushSubscription.optIn();
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    backgroundColor: "#FAFAFA",
    flexGrow: 1,
    // marginTop: 100,
    width: "100%",
    minHeight: "calc(100vh - 80px)",
    // flexGrow: 1,
    padding: "10px",
    marginTop: "75px",
    // marginRight: '20px',
    borderRadius: `8px`,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const dispatch = useDispatch();
  const leftDrawerOpened = useSelector((state) => state.app.is_drawer_opened);
  const { user } = useSelector((state) => state.profile);
  const token = Cookies.get("ticket_hala_system");
  const [initialized, setInitialized] = useState(false);
  const [ticketStatistics, setTicketStatistics] = useState({});
  // fetch all permissions
  // useFetch("/role/permissions", {
  //   onSuccess: (res) => {
  //     dispatch(
  //       setPermissions({
  //         total: res.data.total,
  //         data: res.data.data,
  //       })
  //     );
  //   },
  // });
  const { refetch: refetchTotalTickets } = useFetch("/ticket-statistics", {
    onSuccess: (res) => {
      setTicketStatistics({
        total: res.data.total,
        data: res.data.data,
      });
    },
  });
  // fetch all roles
  // useFetch("/role", {
  //   onSuccess: (res) => {
  //     dispatch(
  //       setRoles({
  //         roles: res.data.data,
  //       })
  //     );
  //   },
  // });

  useEffect(() => {
    if (user.info.id == "") {
      dispatch(Profile.myInfo());
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchTotalTickets();
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  // useEffect(() => {
  //   console.log("==========runOneSignal effect===============");
  //   if (!initialized && user.info?.id) {
  //     console.log("==========runOneSignal inside if===============");
  //     runOneSignal(user.info.id)
  //       .then(() => {
  //         console.log("==========runOneSignal success===============");
  //         setInitialized(true);
  //       })
  //       .catch((e) => console.log("runOneSignal error", e));
  //   }
  // }, [user.info?.id]);

  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        await OneSignal.init({
          appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
          allowLocalhostAsSecureOrigin: true,
          notifyButton: {
            enable: true,
          },
          serviceWorkerParam: {
            scope: "/OneSignalSDKWorker.js",
          },
          serviceWorkerPath: "/OneSignalSDKWorker.js",
          serviceWorkerUpdaterPath: "/OneSignalSDKUpdaterWorker.js",
        });

        setInitialized(true);
        await OneSignal.login(user.info?.id);
      } catch (error) {
        console.error("OneSignal initialization error:", error);
      }
    };

    if (!initialized && user.info?.id) {
      initializeOneSignal();
    }
  }, [initialized, user.info?.id]);

  return token ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar />
      <SideBar
        totalTickets={ticketStatistics?.data?.my_ticket_count}
        totalRequests={ticketStatistics?.data?.ticket_items_request}
      />
      <Main open={leftDrawerOpened}>
        <Outlet />
      </Main>
    </Box>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default MainLayout;
