import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const ticketsApi = factory.get("tickets");

const initialState = {
  tickets: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 15,
      search: "",
      number: "",
      client_id: null,
      status: ["in_progress", "open"],
      ats_id: null,
      created_user_id: null,
      is_related: false,
      tags_id: [],
      is_my_tickets: "",
      department_id: null,
      related_department_id: null,
      priority: "",
      is_private: "",
      start_date: null,
      end_date: null,
      start_update_date: null,
      end_update_date: null,
      is_resolved: null,
    },
    dialog: false,
  },
  ticket: {
    form: {
      client: null,
      ats: null,
      title: "",
      description: "",
      current_department_id: null,
      is_private: true,
      sub_category_id: "1",
      priority: "normal",
      related_to_ticket_id: null,
      attachment: [],
      ticketItems: [],
    },
    dialog: false,
    loading: false,
    errors: {},
    is_from_client: false,
  },
  ticket_details: {
    data: {
      id: "",
      number: "",
      title: "",
      description: "",
      client_id: null,
      client: null,
      current_department_id: "",
      tag_id: null,
      current_department_name: "",
      private: "",
      sub_category_id: 1,
      sub_category_name: "",
      priority: "",
      status: "",
      is_created_by_client: "",
      replays: [],
      created_by_id: "",
      created_by_name: "",
      attachments: [],
      rec_path: "",
    },
    loading: undefined,
    record_dialog: false,
  },
  reply: {
    form: {
      ticket_id: "",
      tag_id: null,
      comment: "",
      is_private: true,
      current_department_id: null,
      status: "in_progress",
      attachment: [],
      assign_user: [],
      rec_path: null,
    },
    loading: false,
    dialog: false,
    record_dialog: false,
    errors: {},
  },
  dialog_tickets_by_id: false,
  approvment: {
    loading: false,
  },
  addItemsState: {
    replyItems: [],
    loading: false,
  },
  search_by_ticket_number: {
    dialog: false,
    data: {},
    filters: {
      number: "",
    },
    loading: false,
  },
  exportLoading: {
    loading: false,
  },
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setTicketDetailLoading: (state, action) => {
      state.ticket_details.loading = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets.data = action.payload.data.data;
      state.tickets.total = action.payload.data.total;
    },
    setTicket: (state, action) => {
      const { id, name, full_name, email, phone_1, phone_2, notes } =
        action.payload.data[0];
      state.ticket.form = {
        id,
        name,
        full_name,
        email,
        phone_1,
        phone_2,
        notes,
        password: "",
      };
      state.ticket.ats = action.payload.data[0].ats;
    },
    setTicketNumberSearchData: (state, { payload }) => {
      state.search_by_ticket_number.data = { ...payload };
    },
    setTicketsFiltersDialog: (state, action) => {
      state.tickets.dialog = !state.tickets.dialog;
    },
    setTicketNumberDialog: (state, action) => {
      state.search_by_ticket_number.dialog =
        !state.search_by_ticket_number.dialog;
    },
    setTicketReplyDialog: (state, { payload }) => {
      state.reply.dialog = !state.reply.dialog;
      state.reply.form.ticket_id = payload ? payload : "";
    },
    resetForm: (state, action) => {
      state.ticket.form = initialState.ticket.form;
    },
    setFilters: (state, { payload }) => {
      state.tickets.filters = {
        ats_id: payload.ats_id || null,
        created_user_id: payload.created_user_id || null,
        status: payload.status || ["in_progress", "open"],
        ...state.tickets.filters,
        ...payload,
      };
    },
    setTicketNumberFilters: (state, { payload }) => {
      state.search_by_ticket_number.filters = {
        ...payload,
      };
    },
    resetTicketNumberFilters: (state) => {
      state.search_by_ticket_number.filters =
        initialState.search_by_ticket_number.filters;
    },
    resetTicketNumberData: (state, action) => {
      state.search_by_ticket_number.data =
        initialState.search_by_ticket_number.data;
    },
    setDialog: (state, { payload }) => {
      switch (payload.type) {
        case "related":
          state.ticket.form.related_to_ticket_id = payload.related_to_ticket_id;
          break;
        case "client":
          state.ticket.is_from_client = true;
          state.ticket.form.client = {
            id: payload.id,
            name: payload.name,
          };
          break;
        case "ats":
          state.ticket.is_from_client = true;
          state.ticket.form.client = {
            id: payload.id,
            name: payload.name,
          };
          state.ticket.form.ats = payload.ats;
          break;
        default:
          state.ticket.form = initialState.ticket.form;
          state.ticket.is_from_client = false;
          break;
      }

      state.ticket.dialog = !state.ticket.dialog;
    },
    setDialogTicketsById: (state, { payload }) => {
      // state.tickets.filters.client_id = payload ? payload : null;
      state.dialog_tickets_by_id = !state.dialog_tickets_by_id;
    },
    setTicketDetails: (state, { payload }) => {
      state.ticket_details.data = { ...payload };
    },

    resetFilters: (state) => {
      state.tickets.filters = initialState.tickets.filters;
    },
    setTicketForClient: (state, { payload }) => {
      state.ticket.is_from_client = payload.is_client;
      state.ticket.form.client = payload.client;
    },
    setReplyItems: (state, action) => {
      state.addItemsState.replyItems = action.payload;
    },
    resetReplyItems: (state, action) => {
      state.addItemsState.replyItems = initialState.addItemsState.replyItems;
    },
    setRecordDialog: (state, { payload }) => {
      state.ticket_details.record_dialog = !state.ticket_details.record_dialog;
    },
    setReplyRecordDialog: (state, { payload }) => {
      state.reply.record_dialog = !state.reply.record_dialog;
    },
  },
});

export const {
  setLoading,
  setTicketDetailLoading,
  setTickets,
  setTicket,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setTicketsFiltersDialog,
  setTicketReplyDialog,
  setTicketDetails,
  setTicketForClient,
  setDialogTicketsById,
  showApprovmentModal,
  setReplyItems,
  resetReplyItems,
  setTicketNumberDialog,
  setTicketNumberFilters,
  setTicketNumberSearchData,
  resetTicketNumberFilters,
  resetTicketNumberData,
  setRecordDialog,
  setReplyRecordDialog,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;

//axios
const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().tickets.tickets.filters;
    const filters_ = {
      ...filters,
      client_id: filters.client_id?.id || null,
      ats_id: filters.ats_id?.id || null,
      created_user_id: filters.created_user_id?.id || null,
      department_id: filters.department_id?.id || null,
      related_department_id: filters.related_department_id?.id || null,
      status: JSON.stringify(filters.status) || null,
      tags_id: JSON.stringify(filters.tags_id?.map((tag) => tag.id)) || null,
    };
    dispatch(setLoading("tickets"));
    const res = await ticketsApi.index(filters_);
    dispatch(setTickets(res));
    dispatch(setLoading("tickets"));
  } catch (err) {
    dispatch(setLoading("tickets"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const indexClient = () => async (dispatch, getState) => {
  try {
    const filters = getState().tickets.tickets.filters;
    const filters_ = {
      ...filters,
      client_id: filters.client_id?.id || null,
      department_id: filters.department_id?.id || null,
      status: JSON.stringify(filters.status) || null,
      tags_id: JSON.stringify(filters.tags_id?.map((tag) => tag.id)) || null,
    };
    dispatch(setLoading("tickets"));
    const res = await ticketsApi.indexClient(filters_);
    dispatch(setTickets(res));
    dispatch(setLoading("tickets"));
  } catch (err) {
    dispatch(setLoading("tickets"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getTicketByid = (id) => async (dispatch, getState) => {
  try {
    dispatch(setTicketDetailLoading(true));
    const res = await ticketsApi.showById(id);
    dispatch(setTicketDetails(res.data.data[0]));
    dispatch(setTicketDetailLoading(false));
  } catch (err) {
    dispatch(setTicketDetailLoading(false));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data, navigate) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("ticket"));
    const formdata = new FormData();
    if (data.ats?.id) {
      formdata.append("ats_id", data.ats.id);
    }
    if (data.client?.id) {
      formdata.append("client_id", data.client?.id);
    }
    if (data.related_to_ticket_id) {
      formdata.append("related_to_ticket_id", data.related_to_ticket_id);
    }

    if (data.tag_id?.id) {
      formdata.append("tag_id", data.tag_id?.id);
    }

    formdata.append("title", data.title);
    formdata.append("description", data.description);
    formdata.append(
      "current_department_id",
      data.current_department_id?.id || null
    );
    formdata.append("is_private", data.is_private);
    formdata.append("sub_category_id", data.sub_category_id);
    formdata.append("priority", data.priority);
    if (data.attachment.length > 0) {
      data.attachment.forEach((item, index) => {
        formdata.append(`attachment[${index}]`, item);
      });
    }
    if (data.ticketItems.length > 0) {
      data.ticketItems.forEach((item, index) => {
        formdata.append(`items[${index}][item_id]`, item.item_id);
        formdata.append(`items[${index}][quantity]`, item.quantity);
      });
    }
    // else formdata.append("attachment[]", null);
    if (data.rec_path) {
      formdata.append("rec_path", data.rec_path.recording_url.slice(0, -1));
    }

    const response = await ticketsApi.create(formdata);
    dispatch(setLoading("ticket"));
    dispatch(
      showNotification({
        type: "success",
        message: "Ticket created successfully",
      })
    );
    dispatch(
      setDialog({
        type: "",
      })
    );
    dispatch(resetForm());
    dispatch(index());
    navigate(`/tickets/${response.data?.data?.[0]?.id}`);
  } catch (err) {
    dispatch(setLoading("ticket"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response?.data?.errors[0],
      })
    );
  }
};
const addItems = (ticketId, data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("addItemsState"));

    await ticketsApi.addItems(data);
    dispatch(
      showNotification({
        type: "success",
        message: "Items added successfully",
      })
    );
    dispatch(getTicketByid(ticketId));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  } finally {
    dispatch(setLoading("addItemsState"));
  }
};
const update = (data) => async (dispatch, getState) => {
  try {
    const { id } = getState().clients.ticket.form;
    dispatch(setLoading("ticket"));
    await ticketsApi.update(id, data);
    dispatch(setLoading("ticket"));
    dispatch(
      showNotification({
        type: "success",
        message: "Ticket updated successfully",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("ticket"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const remove = (id) => async (dispatch, getState) => {
  try {
    await ticketsApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Ticket deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const reply = (data) => async (dispatch, getState) => {
  try {
    const { id, status } = getState().tickets.ticket_details.data;
    const { department_id } = getState().profile.user.info;
    dispatch(setLoading("reply"));
    const formdata = new FormData();
    formdata.append("ticket_id", data.ticket_id);
    if (data.tag_id?.id) {
      formdata.append("tag_id", data.tag_id.id);
    }
    formdata.append("status", status === "closed" ? "re_open" : data.status);
    formdata.append("comment", data.comment);
    formdata.append(
      "current_department_id",
      data.current_department_id
        ? data.current_department_id?.id
        : department_id
    );
    formdata.append("is_private", data.is_private);
    if (data.attachment.length > 0) {
      data.attachment.forEach((item, index) => {
        formdata.append(`attachment[${index}]`, item);
      });
    }
    if (data.replyItems?.length > 0) {
      data.replyItems.forEach((item, index) => {
        formdata.append(`items[${index}][item_id]`, item.item_id);
        formdata.append(`items[${index}][quantity]`, item.quantity);
      });
    }
    if (data.assign_user.length > 0) {
      let assign_user = [];
      data.assign_user.forEach((item, index) => {
        assign_user.push(item.id);
      });
      formdata.append(`assign_user`, JSON.stringify(assign_user));
    }
    if (data.rec_path) {
      formdata.append("rec_path", data.rec_path.recording_url.slice(0, -1));
    }
    await ticketsApi.reply(formdata);
    dispatch(setLoading("reply"));
    dispatch(resetReplyItems());
    dispatch(
      showNotification({
        type: "success",
        message: "Reply created successfully",
      })
    );
    dispatch(setTicketReplyDialog());
    dispatch(getTicketByid(id));
  } catch (err) {
    dispatch(setLoading("reply"));
    dispatch(
      showNotification({
        type: "error",
        message: err?.response?.data?.errors[0],
      })
    );
    throw new Error(err);
  }
};
const removeReply = (id) => async (dispatch, getState) => {
  try {
    const ticket = getState().tickets.ticket_details.data;

    await ticketsApi.deleteReplay(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Reply deleted successfully",
      })
    );
    dispatch(getTicketByid(ticket.id));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changeReplyPrivacy = (id, status) => async (dispatch, getState) => {
  try {
    const ticket = getState().tickets.ticket_details.data;

    await ticketsApi.changeReplayStatus(id, status);
    dispatch(
      showNotification({
        type: "success",
        message: "Reply Privacy Changed Successfully",
      })
    );
    dispatch(getTicketByid(ticket.id));
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const addApprovment =
  (ticketId, replayId, data) => async (dispatch, getState) => {
    try {
      dispatch(setLoading("approvment"));
      const formdata = new FormData();

      formdata.append("status", data.status);
      formdata.append("comment", data.comment);
      formdata.append("to_department_id", data.to_department.id);
      formdata.append("to_tag_id", data.to_tag ? data.to_tag.id : null);

      await ticketsApi.addApprovment(replayId, formdata);
      dispatch(
        showNotification({
          type: "success",
          message: "add approvment successfully",
        })
      );
      dispatch(getTicketByid(ticketId));
    } catch (err) {
      dispatch(
        showNotification({
          type: "error",
          message: err.response.data.errors[0],
        })
      );
      throw new Error(err);
    } finally {
      dispatch(setLoading("approvment"));
    }
  };
const searchTicketNumber = () => async (dispatch, getState) => {
  try {
    const filters = getState().tickets.search_by_ticket_number.filters;
    dispatch(setLoading("search_by_ticket_number"));
    const res = await ticketsApi.searchTicketNumber(filters);
    dispatch(setTicketNumberSearchData(res.data.data[0]));
    dispatch(setLoading("search_by_ticket_number"));
  } catch (err) {
    dispatch(setLoading("search_by_ticket_number"));
    console.log(err);
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};
const markResolveStatus = (ticketId, resolve) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("ticket"));
    const response = await ticketsApi.markResolveStatus(
      { ticket_id: ticketId },
      resolve
    );
    dispatch(setLoading("ticket"));
    dispatch(getTicketByid(ticketId));
    dispatch(
      showNotification({
        type: "success",
        message: `Ticket ${resolve} successfully`,
      })
    );
  } catch (err) {
    dispatch(setLoading("ticket"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response?.data?.errors[0],
      })
    );
  }
};

export const exportExcel = (take) => async (dispatch, getState) => {
  try {
    const filters = getState().tickets.tickets.filters;
    const filters_ = {
      ...filters,
      take: take,
      client_id: filters.client_id?.id || null,
      ats_id: filters.ats_id?.id || null,
      created_user_id: filters.created_user_id?.id || null,
      department_id: filters.department_id?.id || null,
      related_department_id: filters.related_department_id?.id || null,
      status: JSON.stringify(filters.status) || null,
      tags_id: JSON.stringify(filters.tags_id?.map((tag) => tag.id)) || null,
    };
    dispatch(setLoading("exportLoading"));
    const res = await ticketsApi.exportToExcel({
      ...filters_,
      export: "excel",
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `tickets_${dayjs().format("YYYY-MM-DD HH:mm")}.xlsx`;
    a.click();
    dispatch(setLoading("exportLoading"));
  } catch (err) {
    dispatch(setLoading("exportLoading"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};

export const Tickets = {
  index,
  getTicketByid,
  indexClient,
  create,
  update,
  remove,
  reply,
  removeReply,
  changeReplyPrivacy,
  addApprovment,
  addItems,
  searchTicketNumber,
  markResolveStatus,
  exportExcel,
};
