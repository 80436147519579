import api from "../apiConfig";
import paramsFactory from "../../utils/paramsFactory";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/ticket?${p}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  indexTicketMin(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/ticket-min?${p}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  indexClient(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/ticket-client?${p}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  showById(id) {
    return api.get(`/api/v1/ticket/${id}`);
  },
  create(data) {
    return api.post(`/api/v1/ticket`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/ticket/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/ticket/${id}`, data);
  },
  reply(data) {
    return api.post(`/api/v1/reply`, data);
  },
  deleteReplay(id) {
    return api.delete(`/api/v1/reply/${id}`);
  },
  changeReplayStatus(id, data) {
    return api.post(`/api/v1/reply/change-privacy/${id}`, data);
  },
  addApprovment(id, data) {
    return api.post(`/api/v1/reply/approval/${id}`, data);
  },
  addItems(data) {
    return api.post(`/api/v1/reply/addItems`, data);
  },
  searchTicketNumber(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/ticket-search?${p}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  markResolveStatus(id, resolve) {
    return api.post(`/api/v1/ticket/mark-as-${resolve}`, id);
  },
  exportToExcel(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/ticket?${p}`, {
      responseType: "blob",
    });
  },
};
