import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index() {
    return api.get(`/api/v1/pbx-service/calls`);
  },
  download(data) {
    return api.post(`/api/v1/pbx-service/recording`, data);
  },
};
