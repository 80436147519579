import { store } from "../app/store";

export default function Can(permissions) {
  const { user } = store.getState().profile;
  if (!user) {
    return false;
  }
  // const { role } = user.info;
  // if (!role) {
  //   return false;
  // }
  const { permissions: userPermissions } = user.info;
  if (!userPermissions) {
    return false;
  }
  return userPermissions.includes(permissions);
}
