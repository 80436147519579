import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
const reportsApi = factory.get("reports");

const initialState = {
  data: [],
  total: 0,
  loading: false,
  dialog: {
    ticketRecordsReport: {
      open: false,
      details: {
        recording_url: "",
      },
    },
  },
  filters: {
    departmentTicketsSummary: {
      start_created_at: dayjs().format("YYYY-MM-DD"),
      end_created_at: dayjs().format("YYYY-MM-DD"),
      department_id: null,
      related_user_id: null,
    },
    ticketsDurationsSummary: {
      start_created_at: dayjs().format("YYYY-MM-DD"),
      end_created_at: dayjs().format("YYYY-MM-DD"),
      department_id: null,
      is_resolved: null,
    },
    cabinetItemsReport: {
      cabinet_id: null,
    },
    ticketRecordsReport: {
      start_date: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      extension: null,
    },
    missedCallReport: {
      start_date: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      end_date: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      src_trunk_name: "",
    },
  },
  payload: [],
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setReports: (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
    clearReports: (state) => {
      state.data = initialState.data;
      state.total = initialState.total;
      state.payload = initialState.payload;
    },
    setFilters: (state, { payload }) => {
      state.filters[payload.type] = {
        ...state.filters[payload.type],
        ...payload.data,
      };
    },
    resetFilters: (state, { payload }) => {
      if (payload?.type) {
        state.filters[payload.type] = { ...initialState.filters[payload.type] };
      }
    },
    setDialog: (state, { payload }) => {
      if (payload.type) {
        state.dialog[payload.type].open = !state.dialog[payload.type].open;
      }
      if (payload.details) {
        state.dialog[payload.type].details = payload.details;
      }
    },
    setDialogData: (state, { payload }) => {
      state.dialog.data = payload.data || [];
      state.dialog.total = payload.total || 0;
    },
    setDialogLoading: (state, { payload }) => {
      state.dialog[payload].loading = !state.dialog[payload].loading;
    },
    setPayload: (state, { payload }) => {
      state.payload = payload;
    },
  },
});

export const {
  setLoading,
  setReports,
  clearReports,
  setDialog,
  setDialogOpen,
  setFilters,
  setDialogData,
  setDialogLoading,
  setPayload,
  resetFilters,
} = reportsSlice.actions;

export default reportsSlice.reducer;

// Axios
const fetch = (reportName) => async (dispatch, getState) => {
  try {
    dispatch(clearReports());
    dispatch(setLoading());
    const filters = getState().reports.filters;
    const departmentTicketsSummaryFilters =
      reportName === "departmentTicketsSummary"
        ? {
            ...filters[reportName],
            department_id: filters[reportName].department_id?.id || null,
            related_user_id: filters[reportName].related_user_id?.id || null,
          }
        : null;
    const camelToSnakeCaseReportName = (str) =>
      str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
    const response = await reportsApi.fetchReport(
      camelToSnakeCaseReportName(reportName),
      reportName === "departmentTicketsSummary"
        ? departmentTicketsSummaryFilters
        : filters[reportName]
    );
    dispatch(setReports(response.data));
    dispatch(setLoading());
  } catch (error) {
    dispatch(setLoading());
    console.log(error);
    dispatch(
      showNotification({
        message: error.response.data.errors?.[0],
        type: "error",
      })
    );
  }
};
const exportExcel = (reportName) => async (dispatch, getState) => {
  try {
    dispatch(setLoading());
    const filters = getState().reports.filters;
    const departmentTicketsSummaryFilters =
      reportName === "departmentTicketsSummary"
        ? {
            ...filters[reportName],
            department_id: filters[reportName].department_id?.id || null,
            related_user_id: filters[reportName].related_user_id?.id || null,
          }
        : null;
    const camelToSnakeCaseReportName = (str) =>
      str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
    const response = await reportsApi.exportDepartmentTicketsSummaryToExcel(
      camelToSnakeCaseReportName(reportName),
      reportName === "departmentTicketsSummary"
        ? { ...departmentTicketsSummaryFilters, export: "excel" }
        : { ...filters[reportName], export: "excel" }
    );
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `${camelToSnakeCaseReportName(reportName)}_${dayjs().format(
      "YYYY-MM-DD HH:mm"
    )}.xlsx`;
    a.click();
    dispatch(setLoading());
  } catch (error) {
    dispatch(setLoading());
    console.log(error);
    dispatch(
      showNotification({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
};
export const Reports = {
  fetch,
  exportExcel,
};
