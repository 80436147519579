import { TextField, Autocomplete } from "@mui/material";

const options = ["halasat", "FTTH"];

const SelectSrcTrunk = ({ ref, ...props }) => {
  return (
    <Autocomplete
      {...props}
      ref={ref}
      options={options}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          helperText={props.helpertext}
          label={props.label || "Type"}
          disabled={props.disabled}
        />
      )}
    />
  );
};

export default SelectSrcTrunk;
