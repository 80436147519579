import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { AiOutlineArrowRight } from "react-icons/ai";

export const MissedCallColumns = [
  {
    field: "cdr",
    headerName: "Call ID",
    flex: 1,
  },
  {
    field: "caller",
    headerName: "Caller",
    flex: 1.5,
    renderCell: (params) => {
      return <Chip label={params.row.caller} color="error" />;
    },
  },
  {
    field: "ring_duration",
    headerName: "Ringing duration",
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography color="initial">
          {`${params.row.ring_duration} s`}
        </Typography>
      );
    },
  },
  {
    field: "src_trunk_name",
    headerName: "Type",
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography color="initial">{params.row.src_trunk_name}</Typography>
      );
    },
  },
  {
    field: "start",
    headerName: "Started at",
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography color="initial">
          {dayjs(params.row.start).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      );
    },
  },
];
